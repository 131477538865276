$theme-colors: (
	'primary': tomato,
	'info': tomato,
	'danger': teal,
);

@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css');
@import '~bootstrap/scss/bootstrap';
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
@import '../styles/navbar';
@import '../styles/tech_stack';
@import '../styles/proyectos';
@import '../styles/slider';
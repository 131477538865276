.portfolio-section-projects {
    background-color: #F7F5FA;

    h1 {
        padding: 20px 0 0;
    }

    .projects__col {
        display: flex;
        flex-direction: column;
    }

    .project-card {
        // height: 700px;
        margin-bottom: 60px;
        border-radius: 30px;
        border: none;

        flex: 1;
        display: flex;
        flex-direction: column;
        // border: solid 1px red;


        

        .project-card__image-container {
            display: flex;
            justify-content: center;
            align-items: center;
            // height: 440px;
            padding: 30px 40px;
            background: #F5ECFF 0% 0% no-repeat padding-box;
            // border-radius: 30px 30px 0px 0px;
            clip-path: polygon(15% 0, 100% 0, 100% 90%, 85% 100%, 0 100%, 0 10%);

            .project-card__image-container__img {
                // max-width: 100%;
                // height: 100%;
                border-radius: 10px;
                // box-shadow: -10px 0px 10px rgb(0 0 0 / 5%);
                box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;

                &.image-5 {
                    box-shadow: none;
                }

                // @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
                //     height: unset;
                // }
            }
        }

        .project-card__caption {
            padding:  20px 30px 15px;
            flex: 1;
            position: relative;

            @media (min-width: 320px) and (max-width: 480px) {

                padding-left: 15px;
                padding-right: 15px;

            }

            @media (min-width: 768px) and (max-width: 1024px) {
  
              padding-left: 15px;
              padding-right: 15px;
                
              }
        }

        .project-card__link {
            // font: normal normal 800 26px/46px Poppins;
            // letter-spacing: 0px;
            color: #2B2149;
            text-decoration: none;
            font-weight: 800;
            font-size: 20px;
            line-height: 1;
            text-transform: uppercase;
            display: block;
            margin-bottom: 15px;

            &:hover {
                color: #D07BD4;

                svg {
                    fill: #D07BD4;
                }
            }

            @media (min-width: 320px) and (max-width: 767px) {

                font-size: 18px;
                line-height: normal;

            }
        }

        a {
            // display: flex;
            // align-items: center;
            // margin-bottom: 8px;
            // text-transform: uppercase;

            // &:hover{
            //     color: #D07BD4 ;
            //     svg{
            //         fill: #D07BD4;
            //     }
            // }

            svg {
                // font-size: 0.8em;
                // margin-left: 10px;
                // margin-bottom: 0.5rem;
            }
        }

        .project-card__description {
            text-align: left;
            font: normal normal normal 16px/27px Poppins;
            letter-spacing: 0px;
            color: #2B2149;
            opacity: 1;
           

            @media (min-width: 1200px) {
                margin-bottom: 120px;
            }
        }

        small {
            text-align: left;
            font: normal normal normal 15px/27px Poppins;
            letter-spacing: 0px;
            color: #8492A6;
        }

        &:hover {
            box-shadow: 0px 1px 15px #00000026;
            transition: 0.3s;
            .project-card__image-container{
                transition: 0.3s;
                clip-path: none;
                border-radius: 30px 30px 0px 0px;
            }
        }
    }

    h6 {
        font-style: italic;
        font-weight: normal;
        line-height: 21px;

        span {
            font-size: 21px;
        }
    }
}

footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 186px;
    background: #2b2149;
    border: 1px solid #707070;
    color: white;

    .portfolio-footer__social-media {

        font-size: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 30px;

            &:first-of-type {
                margin-left: 0;
            }
        }

        svg {
            fill: #D07BD4;

            &hover {
                transition: 0.3s;
                color: white;
            }

        }
    }

    small {
        display: block;
        text-align: center;

        &:last-of-type {
            font-size: 11px;
        }

        span {
            color: #61DBFB;
        }

        svg {
            fill: #61DBFB;
            font-size: 1.5em;

        }
    }

}

.project-card__tag {
    color: white;
    border-radius: 10px;
    padding: 5px;
    font-size: 11px;
    margin: 7.5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    // &:last-of-type{
    //     margin-right: 0;
    // }
    &.project-card__tag--angular {
        background-color: #DD0031;
    }

    &.project-card__tag--html5 {
        background-color: #f06529;
    }

    &.project-card__tag--sass {
        background-color: #cc6699;
    }

    &.project-card__tag--node {
        background-color: #68a063;
    }

    &.project-card__tag--mysql {
        background-color: #34445a;
    }

    &.project-card__tag--bootstrap {
        background-color: #563d7c;
    }

    &.project-card__tag--react {
        background-color: #61DBFB;
    }

    &.project-card__tag--ionic {
        background-color: #498AFF;
    }

    &.project-card__tag--css3,
    &.project-card__tag--css {
        background-color: #264de4;
    }

    &.project-card__tag--springboot {
        background-color: #63b175;
    }

    &.project-card__tag--api {
        background-color: #414a4c;
    }

    &.project-card__tag--hubspot {
        background-color: #FF7A59;
    }

    &.project-card__tag--php {
        background-color: #6C78AF;
    }

    &.project-card__tag--mailchimp {
        background-color: #FFD14F;
    }

    &.project-card__tag--javascript {
        background-color: #F0DB4F;
    }

    &.project-card__tag--shopify {
        background-color: #64943E;
    }

    &.project-card__tag--cms {
        background-color: #748EC4;
    }

    &.project-card__tag--angularmaterialui {
        background-color: #99b7ff;
    }
}

.project-card__tags {
    
    @media (min-width: 1360px) {
        .col-12.col-lg-6.col-xxl-4{
            padding-left: 0;
            padding-right: 0;
        }
       
        width: calc(100% - 60px);
        
        position: absolute;
        bottom: 15px;
        right: calc(50% + 15px);
        transform: translate(50%, 0%);
    }
}

.project-card__image-container__image{
    border-radius: 5px;
}
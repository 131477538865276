.bg_header {
    background-color: #F8EBFF;
}


@-webkit-keyframes bounce {
    0% {
        transform: translateY(-4px)
    }

    50% {
        transform: translateY(9px)
    }

    100% {
        transform: translateY(-4px)
    }
}

@keyframes bounce {
    0% {
        transform: translateY(-4px)
    }

    50% {
        transform: translateY(9px)
    }

    100% {
        transform: translateY(-4px)
    }
}


.navbar-collapse {

    justify-content: end;


    @media (min-width: 320px) and (max-width: 1200px) {

        background-color: #f4dbf4;
    }
}

.nav-link {
    font-family: Poppins;
    font-weight: normal;
    font-size: 18px;
    text-align: left;
    color: #2b2149;
    text-decoration-color: #D07BD4;

    &:hover {
        color: #D07BD4;
        transition: 0.3s;
        text-decoration: underline;
        text-underline-offset: 10px;
        text-decoration-thickness: 6px;


    }

}

.navbar__logo-container {
    width: 120px;
    height: 50px;
    // background-color: #ffccff;
    background-color: #f4dbf4;
    // border: solid 1px #d07bd442;
    border-radius: 5px;
}

.navbar__logo-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.header__layers--first {
    filter: drop-shadow(0px 3px 15px rgba(43, 33, 73, 0.18));
}

.header__layers {
    position: relative;
    // margin-bottom: 70px;
}

.header__layers--second {
    position: absolute;
    top: 0;
    left: 0;
}



.header__banner {

    @media (min-width: 1200px) {
        position: absolute;
        top: 45%;
        right: 50%;
        transform: translate(50%, -50%);
        z-index: 1;
    }

    .header__avatar {
        max-width: 100%;
        margin: auto;
        display: block;

        -webkit-animation: bounce 3s infinite ease-in-out;
        -o-animation: bounce 3s infinite ease-in-out;
        -ms-animation: bounce 3s infinite ease-in-out;
        -moz-animation: bounce 3s infinite ease-in-out;
        animation: bounce 3s infinite ease-in-out;

        @media (mAX-width: 1200px) {
            width: 60%;
        }
    }

    h1 {
        text-align: left;
        color: #2b2149;
        font: normal normal 800 50px/76px Poppins;
        padding-bottom: 50px;
        margin-bottom: 0;

        @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
            font-size: 47px;
        }

        @media (max-width: 1200px) {
            font: normal normal 800 24px/35px Poppins;
            text-align: center;

            .header__title {
                margin: auto;
            }
        }

        span {
            width: 43px;
            height: 6px;
            background: #d07bd4;
            display: block;
        }
    }

    p {
        text-align: left;
        font: normal normal normal 18px/27px Poppins;
        letter-spacing: 0px;
        color: #2B2149;
        margin-bottom: 50px;

        &:first-of-type {
            margin-bottom: 1rem;
        }
    }

    .header__link {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        width: 188px;
        height: 58px;
        background: transparent linear-gradient(180deg, #AE26C0 0%, #7344C0 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #7344C078;
        border-radius: 29px;
        opacity: 1;
        font: normal normal 600 18px/27px Poppins;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        margin-top: 35px;

        &:hover {
            background: transparent linear-gradient(180deg, #7344C0 0%, #AE26C0 100%) 0% 0% no-repeat padding-box;
            box-shadow: 0px 6px 12px #7344C078;
            color: #FFFFFF;
            // font-weight: bold;

        }

        @media (max-width: 1200px) {
            margin-bottom: 20px;
        }

    }

    .row.flex-row-reverse {
        @media (max-width: 1200px) {
            padding-top: 120px;
        }
    }
}



nav {
    background-color: #F7EAFF;

    .nav-link {
        font-family: Poppins;
        font-weight: normal;
        font-size: 18px;
        text-align: left;
        color: #2b2149 !important;
        cursor: pointer;

        cursor: pointer;
        padding: 0 !important;
        /* background-color: darkgoldenrod; */
        display: flex;
        justify-content: center;
        align-items: center;

        // margin-right: 84px;

        &:hover {
            transition: 0.3s;
            color: #D07BD4 !important;
            // font-weight: 600;
        }

    }

    .navbar__language-selector {

        background-color: transparent;
        padding-left: 10px;
        border-radius: 5px;

        &:hover {
            background-color: #d07bd4;
            //    button{
            //     color: white !important;
            //    }
        }
    }

    .navbar__language-selector__button {
        button {
            background-color: transparent !important;
            border: transparent;
            font-family: Poppins;
            font-weight: normal;
            font-size: 18px;
            color: #2b2149 !important;
            line-height: 31px;

            &:hover,
            &:active,
            &:focus {
                background-color: transparent !important;
                border: transparent;
            }

            // &.dropdown-toggle {
            //     &:hover{
            //         color: white !important;
            //     }
            // }

        }

        .dropdown-menu.show {
            border: none;
            left: unset;
            right: 0;

            a {
                padding-top: 0;
                padding-bottom: 0;

                &:active {
                    background-color: #E6B9EC !important;
                }

                button {
                    font-size: 15px;


                }
            }
        }


    }


    .navbar__language-selector__icon {
        width: 20px;
    }
}

.header__background-img--mobile {

    border-radius: 0px 0px 195px 0px;
    background: linear-gradient(#f5ecff 0%, #fee6ff 100%);
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 100%;
    height: 100%;
    position: absolute;

}

.btn:focus,
.btn:active {
    outline: none !important;
    box-shadow: none !important;
}

.navbar-light .navbar-toggler {
    color: transparent;
    border-color: transparent;
}

.navbar-toggler {
    svg {
        color: #D07BD4;
    }
}

.name_logo {
    width: 185px;
}

.navbar__language-selector__button,
.navbar__language-selector__icon {
    @media (min-width: 320px) and (max-width: 767px) {

        display: none;

    }
}

.navbar__language-selector__button--mobile {
    display: none;

    @media (min-width: 320px) and (max-width: 767px) {

        display: flex;
        border: solid 1px lightgray;
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;

        .navbar__language-selector__icon {
            display: block;
        }

        &:first-of-type {
            margin-bottom: 10px;
        }

        border-color: d07bd4;

        &.active {
            background-color: #d07bd4;
            border-color: transparent;
            color: white;
        }

    }
}

nav .nav-link {
    @media (min-width: 320px) and (max-width: 1200px) {

        padding-bottom: 25px !important;
        margin-top: 25px;

    }
}
.portfolio-section__title {
    text-align: left;
    font: normal normal 800 40px/60px Poppins;
    letter-spacing: 0px;
    color: #2B2149;
    margin-bottom: 50px;
    padding-top: 70px;

    span {
        display: block;
        width: 43px;
        height: 6px;
        background: #D07BD4 0% 0% no-repeat padding-box;
    }
}



.portfolio-section__text{
    font: normal normal normal 18px/27px Poppins;
    letter-spacing: 0px;
    color: #2B2149;
    margin-bottom: 40px;
}

#tecnologias {
    margin-bottom: 96px;

    .card {
        height: 188px;
        // background: #FFFFFF 0% 0% no-repeat padding-box;
        background-color: transparent;
        box-shadow: 0px 1px 15px #0000001A;
        border-radius: 20px;
        opacity: 1;
        border: none;
        margin-bottom: 17px;
        display: flex;




        img {
            object-fit: scale-down;
            width: 100%;
        }

        .card-inner {
            position: relative;
            width: 100%;
            height: 100%;
            text-align: center;
            transition: transform 0.8s;
            transform-style: preserve-3d;
        }

        .card-front,
        .card-back {
            position: absolute;
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: hidden;
            /* Safari */
            backface-visibility: hidden;
        }

        .card-back {
            background: #D07BD4 0% 0% no-repeat padding-box;
            box-shadow: 0px 1px 15px #0000001A;
            border-radius: 20px;
            opacity: 1;
            font: normal normal medium 20px/30px Poppins;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
            transform: rotateY(180deg);
            justify-content: center;
            align-items: center;
            perspective: 1000px;
            display: flex;
            box-shadow: 0px 1px 15px #0000001A;
            border-radius: 20px;
            opacity: 1;
            border: none;

            p {
                margin-bottom: 0;
            }
        }

        .card-front {
            background-color: white;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            perspective: 1000px;
            box-shadow: 0px 1px 15px #0000001A;
            border-radius: 20px;
            opacity: 1;
            border: none;
        }

        &:hover {
            .card-inner {
                transform: rotateY(180deg);
            }
        }


    }
}

.skill-card {
    // border: solid 1px;
    border-radius: 15px;
    padding: 10px 8px;
    background-color: #FAFAFC;
    margin-bottom: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.skill-card__content {}

.skill-card__image-container {
    // border: solid 1px red;
    width: 100px;
    height: 100px;
    min-width: 100px;
    overflow: hidden;
}

.skill-card__image-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.skill-card__description {
    width: calc(100% - 100px);
    margin-bottom: 0;
    text-align: center;
    padding-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .9em;
}